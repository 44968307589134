<!-- 选科推荐报告 -->
<template>
  <div class="main">
    <div class="main-title">
      <span>选科推荐报告</span>
    </div>
    <div class="main-parts-wrapper" v-show="showreport">
      <div class="main-part">
        <div class="part-title">选科推荐结果</div>
        <div class="part-describe">
          选科推荐报告是根据测评数据结合学生的考试成绩，综合得出学生个选科组合的优势情况。通过此项，可以帮助学生做出最佳选择。
        </div>
        <!-- 条形图 -->
        <div class="part-content" id="container" :style="chartheight"></div>
      </div>
    </div>
    <div class="main-parts-wrapper" v-show="!showreport">
      <div class="main-part">
        <div class="part-title">{{ msg }}暂无选科推荐</div>
        <div class="part-describe" style="height: 160px">
          {{ msg }}暂无选科推荐
        </div>
      </div>
    </div>
    <div class="main-buttons-wrapper">
      <a
        class="lj-btn lj-btn-default lj-btn-block main-button"
        @click="jumpPage('reportCenter')"
        >返回目录</a
      >
    </div>
  </div>
</template>

<script>
import './../../../Public/utils/chartjs/Chart'
import './../../../Public/utils/highcharts/highcharts'
import { subjectadvice } from './../../../api/report'
import Highcharts from './../../../Public/utils/highcharts/highcharts'

export default {
  data: function () {
    return {
      showreport: false,
      msg: '',
      score: [76, 23],
      subject: ['计算机', '金融'],
      chartheight: {
        'min-height': '480px'
      },
      chart: {}
    }
  },
  computed: {
    chartOptions: function () {
      return {
        chart: { type: 'bar' },
        xAxis: {
          categories: this.subject,
          title: {
            text: '学科组合',
            style: { fontSize: '16px' }
          },
          labels: {
            step: 1,
            staggerLines: 1,
            style: { fontSize: '14px' }
          }
        },
        title: { text: null },
        yAxis: {
          min: 0,
          max: 100,
          title: {
            text: '匹配度',
            align: 'high',
            style: { fontSize: '16px' }
          },
          labels: { format: '{value}' }
        },
        legend: { enabled: !1 },
        tooltip: { valueDecimals: 2, valueSuffix: '' },
        plotOptions: {
          bar: {
            dataLabels: { enabled: !0, format: '{y}' }
          },
          series: { pointWidth: 30 }
        },
        credits: { enabled: !1 },
        series: [{ name: '匹配度', data: this.score }]
      }
    },
    isMobile: function () {
      return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    }
  },
  created: function () {
    subjectadvice({}).then(res => {
      console.log('subjectadvice', res)
      if (res.rescode == 200) {
        this.showreport = true
        this.score = res.data.socre
        this.subject = res.data.names
        for (let ix = 0; ix < this.score.length; ix++) {
          this.score[ix] = parseInt(this.score[ix])
        }
        this.initChart()
      } else if (res.rescode == 201) {
        this.msg = res.message
      }
    })
  },
  methods: {
    jumpPage: function (dest) {
      dest === '/'
        ? this.$router.replace({ path: dest })
        : this.$router.push({ path: dest })
    },
    initChart () {
      this.chart = new Highcharts.Chart(
        document.getElementById('container'),
        this.chartOptions
      )
    }
  }
}
</script>

<style lang="less" scoped>

@import url('./../../../Public/resources/css/templates/home/major/std_mobile_result.min.css');
div {
  box-sizing: border-box;
}
.part-describe {
  padding: 1rem 2rem;
  text-indent: 1.8rem;
}
.main-title {
  padding: 0;
}

.part-content {
  width: 100%;
}

@media only screen and (max-width: 480px) {
  .main {
    padding: 1.6rem 0;
  }

  .main .main-parts-wrapper {
    padding: 1rem 0;
  }
}
</style>
